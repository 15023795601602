/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faLock from "@fortawesome/fontawesome-free-solid/faLock";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { DateService } from "../../../service/DateService";
import ArticleBox from "../../layout/ArticleBox/ArticleBox";
import ResourceBox from "../../layout/ResourceBox/ResourceBox";
import theme from "../../theme/3.0/theme";

type Props = {
    article;
    marginBottom?;
    routeKey?;
};
const KnowledgeArticleBox = ({ article, marginBottom, routeKey }: Props) => {
    const date = article.PublicationDate
        ? DateService.format(
              article.PublicationDate,
              DateService.FORMAT_DD_MMM_YY
          )
        : article.PublicationDate;

    const link = {
        routeKey: routeKey ? routeKey : "knowledge-view",
        query: { id: article.slug ? article.slug : article.id }
    };

    return (
        <ResourceBox
            topContent={
                <div style={{ position: "relative" }}>
                    <div>
                        {article.featured === "Yes" ? (
                            <div
                                className={"badge"}
                                style={{
                                    backgroundColor: theme.colour.indigo,
                                    color: theme.colour.white
                                }}
                            >
                                Featured
                            </div>
                        ) : null}
                        {article.authorization &&
                            article.authorization.includes("Members") &&
                            !article.authorization.includes("Public") && (
                                <div className={"badge badge-success"}>
                                    Members{" "}
                                    <FontAwesomeIcon
                                        icon={faLock as IconProp}
                                        style={{
                                            paddingBottom: "1px",
                                            height: "0.75em"
                                        }}
                                    />
                                </div>
                            )}
                        {article.author &&
                            article.author.includes("Convergence") && (
                                <div className={"badge badge-secondary"}>
                                    Convergence
                                </div>
                            )}
                        {article.tags?.includes("gender") && (
                            <div className={"badge badge-warning"}>Gender</div>
                        )}
                        {article.tags?.includes("climate") && (
                            <div
                                className={"badge"}
                                style={{ backgroundColor: "#95ce58" }}
                            >
                                Climate
                            </div>
                        )}
                    </div>
                    {article.author}
                </div>
            }
            middleContent={article.title}
            bottomContent={date}
            link={link}
            linkTitle={article.title}
            image={article.image}
            imageTitle={article.title}
            imagePosition={"left-cover"}
            marginBottom={marginBottom}
        />
    );
};

KnowledgeArticleBox.propTypes = {
    article: PropTypes.object.isRequired,
    marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default KnowledgeArticleBox;
