import {
    addDays as dateFNSAddDays,
    addHours as dateFNSAddHours,
    addMinutes as dateFNSAddMinutes,
    format as dateFNSFormat,
    parseISO as dateFNSParseISO,
    subHours as dateFNSSubHours,
    formatDistanceToNow as dateFNSFormatDistanceToNow
} from "date-fns";

/**
 * Centralized service for handling dates through our API.
 */
export class DateService {
    static timeZone = "UTC";
    static FORMAT_JS = "yyyy-MM-dd'T'HH:mm:ss.SSSZ";
    static FORMAT_MYSQL = "yyyy-MM-dd HH:mm:ss";
    static FORMAT_YYYY_MM_DD = "yyyy-MM-dd";
    static FORMAT_YYYY_MM_DD_HH_MM_SS_A = "yyyy-MM-dd hh:mm:ss a";
    static FORMAT_MMM_YYYY = "MMM yy";
    static FORMAT_YYYY = "yyyy";
    static FORMAT_MMM = "MMM";
    static FORMAT_DD = "dd";
    static FORMAT_MMM_DD_YYYY = "MMM do, y";
    static FORMAT_DD_MMM_YY = "dd MMM yy";
    static FORMAT_DD_MMM_YYYY = "dd MMM yyyy";
    static FORMAT_EEEE_MMM_EE_YYYY = "EEEE, MMM do, yyyy (OOOO)";
    static FORMAT_FULL_DATE_AND_TIME = "MMMM do, yyyy 'at' h:mm a";

    /**
     * Compares dateA to dateB and returns a boolean.
     * @param {Date} dateA
     * @param {String} comparison
     * @param {Date} dateB
     */
    static compareDates(dateA, comparison, dateB) {
        if (typeof dateA.getMonth !== "function") {
            throw new Error(
                "Invalid DateA provided. Ensure it is a proper Date object."
            );
        }

        if (typeof dateB.getMonth !== "function") {
            throw new Error(
                "Invalid DateA provided. Ensure it is a proper Date object."
            );
        }

        if (!["=", ">", "<", ">=", "<=", "!="].includes(comparison)) {
            throw new Error(
                "Invalid comparison value. Must be one of: =, <, >, <=, >=, !=."
            );
        }

        switch (comparison) {
            case "=":
                return dateA.getTime() === dateB.getTime();
            case ">":
                return dateA.getTime() > dateB.getTime();
            case "<":
                return dateA.getTime() < dateB.getTime();
            case ">=":
                return dateA.getTime() >= dateB.getTime();
            case "<=":
                return dateA.getTime() <= dateB.getTime();
            case "!=":
                return dateA.getTime() !== dateB.getTime();
        }
    }

    /**
     * Compares date current datetime and returns a boolean.
     * @param {Date} date
     */
    static inFuture(date) {
        if (typeof date.getMonth !== "function") {
            throw new Error(
                "Invalid Date provided. Ensure it is a proper Date object."
            );
        }
        const now = new Date();
        return date.getTime() > now.getTime();
    }

    /**
     * Parse a date string to a Date() object
     * @param {string} dateString Date
     */
    static parse(dateString) {
        if (typeof dateString !== "string") {
            return dateFNSParseISO(dateString);
        }
        return dateFNSParseISO(dateString);
    }

    /**
     * Parse a Date() object to a string with standard MySQL Formatting.
     * @param {Date} date String
     */
    static format(date, format?) {
        if (typeof date === "string") {
            date = dateFNSParseISO(date);
        }
        return dateFNSFormat(
            date,
            typeof format === "undefined" ? this.FORMAT_MYSQL : format
        );
    }

    /**
     * Converts a given Date() object to UTC and outputs a string.
     * @param {Date} date String
     */
    static toString(date) {
        if (typeof date === "string") {
            date = dateFNSParseISO(date);
        }
        const UTCDate = this.toUTC(date);
        return DateService.format(UTCDate);
    }

    /**
     * Today's date as a string.
     */
    static todayString() {
        return dateFNSFormat(DateService.today(), DateService.FORMAT_MYSQL);
    }

    /**
     * Gets the start of the current month
     * as a date object.
     *
     * @static
     * @returns
     * @memberof DateService
     */
    static currentMonth() {
        const date = new Date();
        const utcDate = new Date(
            Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0)
        );
        return utcDate;
    }

    /**
     * Gets a string representation of the current month.
     *
     * @static
     * @returns
     * @memberof DateService
     */
    static currentMonthString() {
        return DateService.format(DateService.currentMonth());
    }

    /**
     * Converts a given date to UTC
     * @param {Date} date Date
     */
    static toUTC(date) {
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );
    }

    /**
     * Gets today's date in UTC.
     */
    static today() {
        const date = new Date();
        return DateService.toUTC(date);
    }

    /**
     * Adds days to a given date or today's dae
     * @param days number
     * @param date Date
     */
    static addDays(days, date) {
        return typeof date !== "undefined"
            ? dateFNSAddDays(date, days)
            : dateFNSAddDays(DateService.today(), days);
    }

    /**
     * Adds hours to a given date or today's dae
     * @param hours number
     * @param date Date
     */
    static addHours(hours, date) {
        return typeof date !== "undefined"
            ? dateFNSAddHours(date, hours)
            : dateFNSAddHours(DateService.today(), hours);
    }

    /**
     * Subtracts hours to a given date or today's dae
     * @param hours number
     * @param date Date
     */
    static subtractHours(hours, date) {
        return typeof date !== "undefined"
            ? dateFNSSubHours(date, hours)
            : dateFNSSubHours(DateService.today(), hours);
    }

    /**
     * Adds minutes to a given date or today's dae
     * @param minutes number
     * @param date Date
     */
    static addMinutes(minutes, date) {
        return typeof date !== "undefined"
            ? dateFNSAddMinutes(date, minutes)
            : dateFNSAddMinutes(DateService.today(), minutes);
    }

    /**
     * Is a valid date?
     * @param date Date | null | undefined
     */
    static isValidDate(date) {
        return (
            !(date instanceof Date) ||
            (date instanceof Date && !isNaN(date.getTime()))
        );
    }

    /**
     * Front-pads a string.
     * @param value number
     * @param digits number
     */
    static padString(value, digits) {
        const stringValue = value.toString();
        return stringValue.length < digits
            ? "0".repeat(digits - stringValue.length) + stringValue
            : stringValue;
    }

    /**
     * Converts a date object (or string) to relative time (from now).
     * @param {Date|string} date
     */
    static formatDistanceToNow(date) {
        if (typeof date === "string") {
            date = DateService.parse(date);
        }
        return dateFNSFormatDistanceToNow(date);
    }
}
