/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import LazyLoad from "react-lazy-load";
import { BRAND_COLOURS } from "../../../constants";
import BaseLink from "../../BaseLink";
import Col from "../../theme/3.0/Grid/Col";
import { URISerializeObject } from "../../../utils/CommonUtils";
import theme from "../../theme/3.0/theme";

const LinkBox = ({ link, title, children, marginBottom }) => {
    if (typeof link !== "object") {
        return (
            <a
                href={link}
                className={"article-box"}
                title={title}
                style={
                    typeof marginBottom !== "undefined"
                        ? { marginBottom }
                        : null
                }
                target={"_blank"}
            >
                <span className={"sr-only"}>{title}</span>
                <span aria-hidden={"true"}>{children}</span>
            </a>
        );
    }

    return (
        <BaseLink routeKey={link.routeKey} params={{ ...link.query }}>
            {({ url }) => (
                <a
                    href={url ? url : "#"}
                    className={"article-box"}
                    title={title}
                    style={
                        typeof marginBottom !== "undefined"
                            ? { marginBottom }
                            : null
                    }
                >
                    <span className={"sr-only"}>{title}</span>
                    <span aria-hidden={"true"}>{children}</span>
                </a>
            )}
        </BaseLink>
    );
};

const DivBox = ({ marginBottom, children }) => (
    <div style={typeof marginBottom !== "undefined" ? { marginBottom } : null}>
        {children}
    </div>
);

DivBox.propTypes = {
    marginBottom: PropTypes.string,
    children: PropTypes.any
};

type Props = {
    image;
    imageTitle;
    imagePosition;
    imageClassName;
    topContent;
    middleContent;
    bottomContent;
    link;
    linkTitle;
    className;
    marginBottom;
    columnSizes?;
};

const ResourceBox = ({
    image,
    imageTitle,
    imagePosition,
    imageClassName,
    topContent,
    middleContent,
    bottomContent,
    link,
    linkTitle,
    className,
    marginBottom,
    columnSizes
}: Props) => {
    className =
        typeof className === "undefined"
            ? "article-box-container"
            : "article-box-container " + className;
    imagePosition =
        typeof imagePosition !== "undefined" ? imagePosition : "bottom";

    if (typeof imageClassName === "undefined") {
        if (imagePosition === "bottom") {
            imageClassName = "article-box-image-bottom";
        }

        if (imagePosition === "cover") {
            imageClassName = "article-box-image-cover";
        }

        if (imagePosition === "bottom-cover") {
            imageClassName = "article-box-image-bottom-cover";
        }
        if (imagePosition === "left-cover") {
            imageClassName = "article-box-image-left-cover";
        }

        if (imagePosition === "square") {
            imageClassName = "article-box-image-square";
        }

        if (imagePosition === "logo") {
            imageClassName = "article-box-image-logo";
        }

        if (imagePosition === "none") {
            imageClassName = "article-box-image-hidden";
        }
    }
    const defaultImage = "/static/logo.svg";
    const BoxContainer = ({ children }) =>
        link ? (
            <LinkBox link={link} title={linkTitle} marginBottom={marginBottom}>
                {children}
            </LinkBox>
        ) : (
            <DivBox>{children}</DivBox>
        );

    BoxContainer.propTypes = {
        children: PropTypes.any
    };

    return (
        <React.Fragment>
            <style jsx global>
                {`
                    .article-box-container {
                        width: 100%;
                        display: flex;
                        flex: 1 1 100%;
                        margin-bottom: 2rem;
                    }
                `}
            </style>
            <style jsx>
                {`
                    .resource-box {
                        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        border-radius: 2px;
                        background-color: white;
                    }
                    .article-box {
                        // display: flex;
                        position: relative;
                        // z-index: 1;
                        margin-bottom: 30px;
                        text-decoration: none;
                        width: 100%;
                    }

                    .article-box-container {
                        // padding: 2px;
                        width: 100%;
                        display: flex;
                        flex: 1 1 100%;
                        margin-bottom: 30px;
                    }

                    .article-box-container.cardboard {
                        display: flex;
                    }

                    .article-box-container.cardboard > a.article-box {
                        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
                        flex: 1 1 100%;
                        // background-color: rgba(0, 0, 0, 0.6);
                        // color: white;
                    }

                    .article-box-container.carousel {
                        padding: 1rem;
                        background: #ebebeb;
                    }

                    .cardboard .article-box-content {
                        padding: 1rem;
                        border-radius: 4px;
                        // box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
                        transition: color 0.23s ease-in-out,
                            background-color 0.23s ease-in-out,
                            border-color 0.23s ease-in-out,
                            box-shadow 0.23s ease-in-out;
                    }

                    // .cardboard .article-box-content:hover {
                    //     box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
                    // }

                    .article-box-container:last-of-kind .article-box {
                        margin-bottom: 0;
                    }

                    // .article-box:hover .article-box-content {
                    //     // background-color: rgba(0, 0, 0, 0.6);
                    // }

                    .article-box-image-wrapper {
                        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
                    }

                    .cardboard .article-box-image-wrapper {
                        box-shadow: none;
                        padding: 1rem;
                        background: white;
                    }
                    .cardboard .article-box-image-wrapper.hide {
                        display: none;
                    }

                    .carousel .article-box-image-wrapper {
                        box-shadow: none;
                    }

                    .article-box-image-hidden {
                        visibility: hidden;
                        display: none;
                    }

                    .article-box-image-square {
                        width: 600px;
                        height: 600px;
                        max-width: 100%;
                        max-height: 200px;
                    }

                    .article-box-image-logo {
                        width: auto;
                        max-height: 100px;
                        max-width: 100%;
                    }

                    .article-box-image-cover {
                        position: absolute;
                        max-width: 100%;
                        max-height: 100%;
                        bottom: 0%;
                        top: 0%;
                        right: 0%;
                        left: 0%;
                        margin: auto;
                        z-index: -1;
                    }

                    .article-box-image-bottom {
                        position: absolute;
                        bottom: 7.5%;
                        max-width: 85%;
                        max-height: 30%;
                        left: 7.5%;
                        right: 7.5%;
                        margin: auto;
                        z-index: -1;
                    }

                    .article-box-image-bottom-cover {
                        width: 100%;
                        height: 200px;
                        z-index: -1;
                        object-fit: cover;
                        object-position: left center;
                    }

                    .article-box-image-left-cover {
                        width: 100%;
                        height: 100%;
                        max-height: 100%;
                        z-index: -1;
                        object-fit: cover;
                        object-position: left center;
                    }

                    @media (min-width: ${theme.breakpoint.md}) {
                        .article-box-image-bottom-cover {
                            height: 8rem;
                        }
                    }
                    @media (min-width: ${theme.breakpoint.lg}) {
                        .article-box-image-bottom-cover {
                            height: 9rem;
                        }
                    }
                    @media (min-width: ${theme.breakpoint.xl}) {
                        .article-box-image-bottom-cover {
                            height: 11rem;
                        }
                    }

                    .article-box-content {
                        padding: 0.1rem;
                        height: auto;
                        color: ${BRAND_COLOURS.grayDark};
                        -webkit-font-smoothing: antialiased;
                        // background-color: rgba(0, 0, 0, 0.6);
                    }

                    .article-box-top-content {
                        color: ${BRAND_COLOURS.dark};
                        font-size: 0.8rem;
                    }

                    .article-box-middle-content {
                        font-weight: bold;
                        margin: 0.4rem 0;
                    }

                    .cardboard .article-box-middle-content {
                        color: ${BRAND_COLOURS.primary};
                    }

                    .article-box-bottom-content {
                        color: ${BRAND_COLOURS.dark};
                        font-size: 0.8rem;
                    }

                    .sr-only {
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        padding: 0;
                        overflow: hidden;
                        clip: rect(0, 0, 0, 0);
                        white-space: nowrap;
                        border: 0;
                    }
                `}
            </style>
            <Col
                xl={columnSizes && columnSizes.xl ? columnSizes.xl : 6}
                lg={columnSizes && columnSizes.lg ? columnSizes.lg : 6}
                md={columnSizes && columnSizes.md ? columnSizes.md : 6}
                sm={columnSizes && columnSizes.sm ? columnSizes.sm : 6}
                xs={columnSizes && columnSizes.xs ? columnSizes.xs : 12}
                className={className}
            >
                <BoxContainer>
                    <div
                        className={
                            "d-flex flex-row fluid-container h-100 p-0 resource-box"
                        }
                    >
                        <div className={"row no-gutters"}>
                            <div className={"col col-md-5 p-1"}>
                                <div
                                    className={`article-box-image-wrapper ${
                                        imagePosition === "none" ? "hide" : ""
                                    }`}
                                >
                                    {/* <LazyLoad width="300"> */}
                                    <img
                                        // @ts-ignore POSSIBLE ERROR FOUND. Multiple issues
                                        src={URISerializeObject(
                                            {
                                                fit: "scale",
                                                w: "640",
                                                q: "50",
                                                fm: "jpg",
                                                fl: "progressive"
                                            },

                                            image || defaultImage
                                        )}
                                        alt={
                                            imageTitle ||
                                            "Convergence Blended Finance"
                                        }
                                        className={imageClassName}
                                    />
                                    {/* </LazyLoad> */}
                                </div>
                            </div>
                            <div
                                className={
                                    "col col-md-7 p-3 article-box-content"
                                }
                            >
                                {topContent && (
                                    <div className={"article-box-top-content"}>
                                        {topContent}
                                    </div>
                                )}
                                {middleContent && (
                                    <div
                                        className={"article-box-middle-content"}
                                    >
                                        {middleContent}
                                    </div>
                                )}
                                {bottomContent && (
                                    <div
                                        className={"article-box-bottom-content"}
                                    >
                                        {bottomContent}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </BoxContainer>
            </Col>
        </React.Fragment>
    );
};

ResourceBox.propTypes = {
    image: PropTypes.string,
    imageTitle: PropTypes.string,
    imagePosition: PropTypes.oneOf(["cover", "bottom", "bottom-cover"]),
    imageClassName: PropTypes.string,
    topContent: PropTypes.any,
    middleContent: PropTypes.any,
    bottomContent: PropTypes.any,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    linkTitle: PropTypes.string,
    className: PropTypes.string,
    marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ResourceBox;
